<template>
    <div>
      <CCard class="p-4">
        <CRow>
          <CCol lg="6">
            <h5>Ledger Payments</h5>
          </CCol>
          <CCol lg="6">
           
          <CButton
            size="sm"
            style="margin-right: 10px"
            color="secondary"
            @click="backToViewPersonnelLedger"
            class="float-lg-right"
          >
          <i class="fa fa-arrow-left"></i>Back</CButton
          >
        
        </CCol>
         
        </CRow>
  
        <hr />
        <CRow v-for="item in dataLedgerInfo">        
        
        <CCol lg="3">
          <span >
            <h2 class="mb-0 text-primary">{{item.employee_name}}</h2>
            <p>
              <em>{{item.personnel_type}}</em><br />
              <strong style="text-transform:uppercase; font-size:12px;color:green">{{item.status}}</strong>
            </p>
          </span>
        </CCol>
        <CCol lg="4">
          <p style="margin-top: 10px"> 
            <strong style="margin-right: 34px">Cash Advance Payment:</strong> {{item.ca_deduc_sum  | number_format('currency')}}<br/>
            <strong style="margin-right: 73px">Charges Payment:</strong> {{item.ch_deduc_sum  | number_format('currency')}}<br/>
            <strong style="margin-right: 10px">Other Deductions Payment:</strong> {{item.otherdeductions_deduc_sum  | number_format('currency')}}<br/>
          </p>
        </CCol>
        <CCol lg="3">
          <p style="margin-top: 10px">
            <strong style="margin-right: 24px">Pagibig Loan Payment:</strong> {{item.pagibig_deduc_sum  | number_format('currency')}}<br/>
            <strong style="margin-right: 50px">SSS Loan Payment:</strong> {{item.sss_deduc_sum  | number_format('currency')}}
          </p>
        </CCol>
        <CCol lg="2">

          <span >
            <h5 class="mb-0 text-primary">Total Balance</h5>
            <h3>
              {{item.personnel_ledger_Total | number_format('currency')}}
            </h3>
          </span>
         
        </CCol>      
      </CRow>
        <hr />
        <CRow>
        <CCol lg="12">
          <CRow>
            <CCol lg="9" class="form-inline">
              <div>
              <h6>Date From</h6>
              <div class="form-inline">
                <Datepicker
                  input-class="form-control bg-white"
                  v-model="date_from"
                  
                >
                </Datepicker>
                <i class="fa fa-calendar ml-2"></i>
              </div>
            </div>
              <div class="ml-3">
              <h6>Date To</h6>
              <div class="form-inline">
                <Datepicker
                  input-class="form-control bg-white"
                  v-model="date_to"
                  
                >
                </Datepicker>
                <i class="fa fa-calendar ml-2"></i>
              </div>
            </div>

              <div class="ml-3 mt-4">
							<CButton 
							size="sm"
							color="info" @click="filterList" 
							>
							<i class="fa fa-filter"></i> Filter
							</CButton>	

						</div>	
            
            </CCol>	
            <CCol lg="3" class="mt-3" >
            <CButton
            v-if="config.getPermission('personnel_ledger').create"
            size="sm"
            style="margin-right: 10px"
            color="success"
            @click="addModal()"
            class="float-lg-right"
            ><i class="fa fa-plus"></i>Add Deduction
          </CButton>
          </CCol>
            
          </CRow>

          <CRow> </CRow>
        </CCol>
        
      </CRow>
       
        <br>
        
        <CRow>
         
          <CCol lg="12">
            <div class="table-responsive-sm table-responsive-md">
            <CDataTable
              pagination
             :loading="isLoading"
              :items="dataList.data"
              :fields="fields"
              hover
              outlined
              :tableFilter='{ placeholder: "Type, amount, date , remarks" }'              
              itemsPerPage="10"
              itemsPerPageSelect
  
            >
             
              <template #action="{ item }">
                <td style="padding: 5px; width: 100px">
                  <CDropdown toggler-text="Select" size="sm" color="info">
                   
                    <CDropdownItem
                      v-if="config.getPermission('personnel_ledger').update"
                      @click="updateModal(item)"
                      ><i class="fa fa-pencil-square-o">
                        Update
                      </i></CDropdownItem
                    >
                    <CDropdownItem
                      v-if="config.getPermission('personnel_ledger').delete"
                      @click="deleteItem(item)"
                      ><i class="fa fa-trash"> Delete</i></CDropdownItem
                    >
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
            <!-- <CPagination
              :active-page.sync="currentPage"
              :pages="Math.ceil(dataList.total / 20)"
              :activePage="currentPage"
              @update:activePage="updatePage"
            /> -->
          </div>
          </CCol>
        </CRow>         
      </CCard>        
    <!-- DEDUCTION -->
    <CModal
      :title="title"
      :show.sync="deductionModal"
      @close="closeModal()"
      color="info"
      size="lg"
      
    >
      <form @submit.prevent="submit">
     
          <CRow>
            <CCol class="col-md-12">
              <div class="form-group">
                <label style="font-size: 12px;" for="charges_type"
                  >Type<span class="text-danger"> *</span>
                </label>
                <CInputRadioGroup
                  :options="[
                    {
                      value: 'cash_advance',
                      label: 'Cash Advance',
                      props: { required: 'true' }
                    },
                    {
                      value: 'charges',
                      label: 'Charges',
                      props: { required: 'true' }
                    },
                    {
                      value: 'sss_loan',
                      label: 'SSS Loan',
                      props: { required: 'true' }
                    },
                    {
                      value: 'pagibig_loan',
                      label: 'Pag-ibig Loan',
                      props: { required: 'true' }
                    },
                    {
                      value: 'other_deductions',
                      label: 'Other Deductions',
                      props: { required: 'true' }
                    },
                  ]"
                  :checked.sync="personnelLedgerParam.entry_type"
                  :inline="true"
                  required="true"
                  
                />
              </div>
            </CCol>
          </CRow>
          
				<CRow>
				
				
				</CRow>
				
          <CRow>
            <CCol lg="12">
              <div class="form-group">
              <label style="font-size: 12px;"
                >Deduction Amount
                <span class="text-danger">* </span></label>
              <CInput type="text" v-model="personnelLedgerParam.amount"
              @keypress="isNumber($event)" 
              required="true" class="mb-0" :class="{'has-error' : hasError}"/>
              <div v-show="hasError">
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'cash_advance'">
                  Deduction amount should not be greater than with cash advance balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'charges'">
                  Deduction amount should not be greater than with charges balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'sss_loan'">
                  Deduction amount should not be greater than with sss loan balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'pagibig_loan'">
                  Deduction amount should not be greater than with pagibig loan balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'other_deductions'">
                  Deduction amount should not be greater than with other deduction balance.
                </small>

              </div>
            </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12">
              <small
                >Date
                <span class="text-danger">* </span></small >
              <Datepicker
                input-class="form-control bg-white"
                :typeable="true" 
                class="mb-2"  
                v-model="personnelLedgerParam.entry_date"
                required
              >
              </Datepicker>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12">
              <div class="form-group">
              <label style="font-size: 12px;">Remarks</label>
              <textarea
                class="form-control"
                v-model="personnelLedgerParam.remarks"
              ></textarea>
            </div>
            </CCol>
          </CRow>
      
        <div slot="footer" class="w-100 mt-3">
         
          <template >
            <CButton
              type="submit"
              style="border-radius: 0.2rem; color: white"
              color="info"
              class="ml-1 mr-1 float-right"
              size="sm"
              v-if="!hasError"
            >
              Save
            </CButton>
            <a href="javascript:;" class="btn btn-sm btn-info disabled ml-1 mr-1 float-right" v-else>Save</a>
            
          </template>
          <CButton
            style="border-radius: 0.2rem; color: white"
            color="danger"
            class="ml-1 mr-1 float-right"
            @click="closeModal()"
            size="sm"
          >
            Close
          </CButton>
        </div>
      </form>
      <div slot="footer" class="w-100"></div>
    </CModal>      
    </div>    
  </template>
  <script>
  import config from "../../config.js";
  import axios from "../../axios";
  import Swal from "sweetalert2";
  import Search from "./search";
  import Datepicker from "vuejs-datepicker";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import moment from "moment";
  
  export default {
    mounted() {
      this.defaultDateFrom();
      this.getEntryList();
      this.getLedgerInfo();
    },
    computed: {
    
    is_disabled() {
      return this.mode.view;
    },
  
    hasError() {
        let type = this.personnelLedgerParam.entry_type;
        let inputted = this.personnelLedgerParam.amount ?? 0;
        let balance = 0;
        
        if(!this.dataLedgerInfo.length) return false;

        if(type == 'cash_advance') { //cash advance
          balance = this.dataLedgerInfo[0].personnel_ledger_CAB ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'charges') { //charges
          balance = this.dataLedgerInfo[0].personnel_ledger_CB ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'pagibig_loan') { //pagibig
          balance = this.dataLedgerInfo[0].personnel_ledger_PagibigBalance ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'sss_loan') { //sss loan
          balance = this.dataLedgerInfo[0].personnel_ledger_SSSBalance ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'other_deductions') { //other deduction
          balance = this.dataLedgerInfo[0].personnel_ledger_OtherDeductionsBalance ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
    }
  },
   
    data() {
      return {
       
        isLoading: false,
        config,
        dataParams: {},
        dataLedgerInfo: [],
        dataList: [],
        deductionModal: false,
        personnelLedgerParam: {
        entry_type : "",
        remarks: null,        
		    entry_date: null,
		    amount: null,
        is_deduction: null,
        id: null,
      },
        fields: [
          {
            key: "entry_type",
            label: "Type",
          },
          {
            key: "amount",
            label: "Deduction Amount",
          },
          {
            key: "entry_date",
            label: "Date",
          },
  
          {
            key: "remarks",
            label: "Remarks",
          },
          {
            key: "action",
            label: "Action",
          },
        ],
        currentPage: 1,
        selectedRow: {},
        showCollapse: false,  
        showViewModal: false,
        limit: 10
      };
    },
    name: "Tables",
    components: { Search, Datepicker, vSelect },
    methods: {
     
        backToViewPersonnelLedger(){
            this.$router.push('/main/view_personnel_ledger/'+this.$route.params.id)
	    	// this.$router.push('/data/view_vehicle/'+this.$route.params.id)
	    },
      getLedgerInfo() {
        this.$showLoading(true)	    
        axios.get(config.api_path+'/ledger/list/info',{
                  params : {
                      id : this.$route.params.id
                  }
              }).then((res) => {
                this.$showLoading(false)	    
            this.dataLedgerInfo = res.data.data;                             
          })
      },
      
      filterList() {
      this.getEntryList()
      
    },
      defaultDateFrom(){          
      var date = new Date();
      this.date_to = this.date = moment(date).format('DD MMM YYYY');
      date.setDate(date.getDate() - 30);
      this.date_from = this.date = moment(date).format('DD MMM YYYY');
      this.filterList();
    },
      addModal() {
      this.setMode("new");
      this.deductionModal = !this.deductionModal;
      this.title = "Add Deduction";      
      },
      closeModal(){
        this.deductionModal = false;
      },
      download() {
        this.getEntryList("download");
      },
  
      reloadData() {
        this.getEntryList();
      },
      updatePage(data) {
        this.currentPage = data;
        this.getEntryList();
      },
      setMode(type) {
      this.mode = {
        view: false,
        edit: false,
        new: false,
      };
      this.mode[type] = true;
    },
      submit() {      
        if(this.personnelLedgerParam.amount == null || this.personnelLedgerParam.entry_date == null) {
          Swal.fire({
              title: 'warning',
              text: "Please fill out the fields.",
              icon: 'warning', 
            })	
        }
        this.$showLoading(true)      
        axios.post(config.api_path+'/ledger/process',{
          flag: this.personnelLedgerParam.id != null ? 1:0, // 0 insert, 1 update, 2 delete
          type : this.personnelLedgerParam.entry_type,
          amount : this.personnelLedgerParam.amount,
          date : moment(this.personnelLedgerParam.entry_date).format("y-M-D"),
          remarks : this.personnelLedgerParam?.remarks ?? null,
          is_deduction : 1,
          personnel_id : this.$route.params.id,
          id : this.personnelLedgerParam.id,
          
        }).then(res => {
          this.$showLoading(false)          
          this.deductionModal = false;
          this.getEntryList();
          this.getLedgerInfo();
          this.ResetLedgerParam();          	
        })
        .catch(err => {
          this.$showLoading(false)
        })  
        if(this.personnelLedgerParam.id != null) {
          Swal.fire({
              title: 'Success',
              text: "Deduction entry successfully updated.",
              icon: 'success', 
          })	 
        } else {
          Swal.fire({
              title: 'Success',
              text: "Deduction entry added successfully.",
              icon: 'success', 
          })
        }    		       
    },
      updateModal(item){
        console.log(item);
        this.title = "Update Entry";
        this.selectedRow = item;
        this.personnelLedgerParam = {...item};
        switch (this.personnelLedgerParam.entry_type) {
        case 'Charges':
              this.personnelLedgerParam.entry_type = 'charges';
        break;
        case 'Cash Advance':
              this.personnelLedgerParam.entry_type = 'cash_advance';
        break;
        case 'SSS Loan':
              this.personnelLedgerParam.entry_type = 'sss_loan';
        break;
        case 'Pagibig Loan':
              this.personnelLedgerParam.entry_type = 'pagibig_loan';
        break;
        case 'Other Deductions':
              this.personnelLedgerParam.entry_type = 'other_deductions';
        break;
 
       
      }
        this.deductionModal = true;          
      },
      deleteItem(item){
        Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete this deduction ?",
        showCancelButton: true,
        confirmButtonText: `Yes, delete`,
        confirmButtonColor : 'red',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(config.api_path+'/ledger/process',{
            flag: 2, // 0 insert, 1 update, 2 delete
            type : item.type,
            amount : item.amount,
            date : moment(item.date).format("y-M-D"),
            remarks : item?.remarks ?? null,
            is_deduction : null,
            personnel_id : this.$route.params.id,
            id : item?.id ?? null,           
          }).then(res => {
            this.$showLoading(false)
            this.formModal = false;
            this.getEntryList();
            this.getLedgerInfo();
            Swal.fire({
              title: 'Success',
              text: "Deduct entry successfully deleted.",
              icon: 'success', 
            })	
          })
          .catch(err => {
            this.$showLoading(false)
          })          
        }
      });   
      },
      ResetLedgerParam() {                  
         this.personnelLedgerParam.entry_type = "",
         this.personnelLedgerParam.amount= "",
         this.personnelLedgerParam.entry_date= null,
         this.personnelLedgerParam.is_deduction = null,
         this.personnelLedgerParam.remarks = "",
         this.personnelLedgerParam.id = null
	    },
      // search(event) {
      //   this.filter = event;
      //   this.getEntryList();
      // },

      getEntryList() {
        this.$showLoading(true)
        axios.get(config.api_path+'/ledger/deduction-list',{
        params : {
          personnel_id : this.$route.params.id,
          date_from : moment(this.date_from).format("y-M-D"),
          date_to : moment(this.date_to).format("y-M-D")
        }
      }).then((response) => {
        this.$showLoading(false)
          this.dataList = response.data;
          this.dataList.data = this.dataList.data.map(item => {
              item.entry_date = item.entry_date ? moment(item.entry_date ).format('MM/DD/YYYY') : '';
              item.entry_type_holder = item.entry_type;
             switch(item.entry_type) {
              case 'charges':
                item.entry_type = 'Charges';
                break;
              case 'cash_advance':
                item.entry_type = 'Cash Advance';
                break;
              case 'pagibig_loan':
                item.entry_type = 'Pag-ibig Loan';
                break;
              case 'sss_loan':
                item.entry_type = 'SSS Loan';
                break;
                case 'other_deductions':
                item.entry_type = 'Other Deductions';
                break;
            }
              item.amount = item.amount ? this.$options.filters.number_format(item.amount, '', 2) : '';     
              return item;
          });
      });      
      },

    },
  };
  </script>
  